import { Box } from '@mui/material';
import DropdownIcon from '@/shared/icons/dropdown-icon.svg';
import Image from '@/web/views/components/image/image';
import React from 'react';

export const DropDownArrow = (
  <Box
    width={18}
    height={18}
    alignItems="center"
    display={'flex'}
    justifyContent="center">
    <Image
      alt="Open menu"
      loading={'eager'}
      src={DropdownIcon.src}
      width={13}
      height={7.84}
    />
  </Box>
);

export const DropDownStyle = (open: boolean) => {
  return {
    padding: '2px',
    right: 8,
    position: 'absolute',
    transform: open ? 'scaleY(-1)' : 'none',
  } as React.CSSProperties;
};
