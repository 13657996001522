import { useAuth } from '@/shared/hooks/auth-hook';
import { BasicCarCardType, useBasicCar } from '@/shared/hooks/basic-car-hook';
import hoverFavorite from '@/shared/icons/favorite/hover_favorite.svg';
import isFavoriteIcon from '@/shared/icons/favorite/is_favorite.svg';
import notFavoriteIcon from '@/shared/icons/favorite/not_favorite.svg';
import { ClassifiedItem } from '@/shared/lib-api';
import { LoadingState } from '@/shared/util/general-helpers';
import {
  cursorPointer,
  formatDate,
} from '@/shared/util/helpers/general-helper';
import Image from '@/web/views/components/image/image';
import { Box, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface BasicCarListHeartProps {
  item?: ClassifiedItem;
  origin: BasicCarCardType;
}
export const BasicCarListHeart = (props: BasicCarListHeartProps) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { favorites } = useAuth();

  const [isHovering, setIsHovered] = useState(false);
  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);

  const {
    loginAndLike: loginAndLike,
    addLike,
    removeLike,
    isCurrentlyLiked,
    loading,
  } = useBasicCar({
    item: props.item,
  });

  const [initialLoggedInState, setInitialLoggedInState] =
    useState<boolean>(false);

  useEffect(() => {
    setInitialLoggedInState(isLoggedIn);
  }, [isLoggedIn]);

  const handleFavorite = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // if loggedin => toggle favorite
    // if not loggedin => always add
    e.preventDefault();
    e.stopPropagation();
    if (initialLoggedInState) {
      if (isCurrentlyLiked) {
        removeLike();
      } else {
        addLike();
      }
    } else {
      loginAndLike();
    }
    setIsHovered(false);
  };

  const createdDate = favorites?.find(
    fav => fav.classifiedId === props.item.id,
  )?.createdDate;

  return (
    <>
      {props.origin === BasicCarCardType.SRPList ||
      props.origin === BasicCarCardType.SRPGallery ? (
        <Box
          bgcolor={'red'}
          height={'0px'}
          display={'flex'}
          minWidth={'40px'}
          justifyContent={'flex-end'}>
          <Box
            position={
              props.origin !== BasicCarCardType.SRPList ? 'relative' : 'unset'
            }
            right={props.origin !== BasicCarCardType.SRPList ? 15 : 'unset'}
            top={props.origin !== BasicCarCardType.SRPList ? 36 : 'unset'}
            sx={cursorPointer}
            zIndex={10}
            onClick={
              loading === LoadingState.Loading ? () => {} : handleFavorite
            }
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {isCurrentlyLiked ? (
              <>
                <Image
                  width={40}
                  height={40}
                  alt={t('Labels.Saved')}
                  title={t('Labels.Saved')}
                  src={isFavoriteIcon.src}
                />
              </>
            ) : isHovering ? (
              <Image
                width={40}
                height={40}
                alt={t('Labels.Save')}
                title={t('Labels.Save')}
                src={hoverFavorite.src}
              />
            ) : (
              <Image
                width={40}
                height={40}
                alt={t('Labels.Save')}
                title={t('Labels.Save')}
                src={notFavoriteIcon.src}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={[cursorPointer, { display: { xs: 'none', sm: 'block' } }]}
          zIndex={10}>
          <Typography>
            {props.item !== undefined ? (
              t('MyBilhandel.Favourites.SavedAt', {
                date: formatDate(createdDate),
              })
            ) : (
              <Skeleton width={150} />
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};
