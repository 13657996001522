import { Colors } from '@/shared/util/colors';
import { Box } from '@mui/material';
import React from 'react';

export const DefaultInputWrapper: React.FC<{
  error?: string;
  disable?: boolean;
  textArea?: boolean;
  children?: React.ReactNode;
}> = props => {
  return (
    <Box
      border={props.error ? '2px solid ' + Colors.Error : '1px solid #D9D9D9'}
      padding={props.error ? '0px' : '1px'}
      bgcolor={
        props.error
          ? Colors.ErrorBackGround
          : props.disable
          ? '#F5F5F5'
          : Colors.White
      }
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      width={'100%'}
      height={props.textArea ? 'unset' : 40}
      borderRadius={0.5}>
      {props.children}
    </Box>
  );
};
