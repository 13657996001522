import { ClassifiedItem, UserProfileType } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  FooterLocationDisplay,
  HorsePowerOrCapacityDisplay,
  HorsepowerDisplay,
  MileageDisplay,
  PropellantDisplay,
  RegDateDisplay,
} from '@/shared/util/helpers/data-display-helper';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Box, Divider, Grid, styled, Typography } from '@mui/material';
import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NewVehicleTag } from '../../tags/new-vehicle-tag';
import { GalleryBoxGalleryCard } from '../../default-components/gallery/gallery-box-srp';
import { DefaultTag } from '../../tags/tag';
import { BasicCarCardType, useBasicCar } from '@/shared/hooks/basic-car-hook';
import { BasicCarListHeart } from './basic-car-list-heart';
import { LoweredPriceTag } from '../../tags/lowered-price-tag';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { HasGuaranteeTag } from '../../tags/has-guarantee-tag';
import { TrackEvent } from '@/shared/util/gtm';
import { VipLink } from '../../default-components/vip-link';
import { GetSmallImage } from '@/shared/util/helpers/image-helper';
import { EngrosTag } from '../../tags/engros-tag';

interface BasicCarGalleryCardProps {
  item?: ClassifiedItem;
  'exclude-owner'?: boolean;
  origin: BasicCarCardType;
}

const MakeModel = styled(Typography)({
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '30px',
});

const Variant = styled(Typography)({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Price = styled(Typography)({
  fontSize: '24px',
  fontWeight: '500',
  color: Colors.DarkBlue,
});

const PriceLabel = styled(Typography)({
  fontSize: '10px',
  fontWeight: '400',
  color: '#9D9D9D',
});

const FooterTypography = styled(Typography)({
  fontSize: '15px',
  fontWeight: '500',
});

export const BasicCarGalleryCard = (props: BasicCarGalleryCardProps) => {
  const { t } = useTranslation();
  const {
    userCoordinates,
    carRoute,
    isLoweredInPrice,
    isNewVehicle,
    hasGuarantee,
    isEngros,
  } = useBasicCar({
    item: props.item,
  });

  const dataList = useMemo(() => {
    let result: string[] = [];
    if (props.item.yearOfRegistration) {
      result.push(RegDateDisplay(props.item));
    }
    if (props.item.mileage) {
      result.push(MileageDisplay(props.item.mileage));
    }

    if (HorsePowerOrCapacityDisplay(props.item)) {
      result.push(HorsePowerOrCapacityDisplay(props.item));
    }
    if (props.item.fueltype) {
      result.push(PropellantDisplay(props.item.fueltype));
    }
    return result;
  }, [props.item]);

  const trackClick = useCallback(() => {
    if (props.origin == BasicCarCardType.AlternativesModal) {
      TrackEvent('modal-alternative-clicked', { id: props.item.id });
    }
  }, [props]);
  return (
    <Grid onClick={trackClick} item xs={12} sm={6} md={4} sx={cursorPointer}>
      <VipLink item={props.item}>
        {props.item !== undefined &&
          props.origin !== BasicCarCardType.AlternativesModal && (
            <BasicCarListHeart origin={props.origin} item={props.item} />
          )}
        <Box>
          <GalleryBoxGalleryCard
            minHeight={0}
            items={props.item?.images.map(p => GetSmallImage(p.folderId, p.id))}
            top-border={true}
            alt={props.item?.headLine}
          />
        </Box>

        <Box
          sx={{
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            padding: {
              xs: '11px 15px 10px 15px',
              md: '21px 20px 14px 20px',
            },
          }}
          bgcolor={Colors.White}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            justifyContent={'space-between'}>
            <Box>
              <Grid container width={'100%'} justifyContent="space-between">
                <Grid
                  item
                  maxWidth={
                    hasGuarantee() ||
                    isNewVehicle(
                      props.item?.systemData.createdDate.toString(),
                    ) ||
                    isLoweredInPrice()
                      ? 230
                      : 'none'
                  }>
                  <MakeModel>
                    {props.item?.make} {props.item?.model}
                  </MakeModel>
                  <Variant>{props.item?.alternativeVariant}</Variant>
                </Grid>
                {props.item?.systemData.createdDate && (
                  <Grid item>
                    <Box display={'flex'}>
                      <Box>
                        {isNewVehicle(
                          props.item?.systemData.createdDate.toString(),
                        ) && (
                          <Box
                            marginBottom={1}
                            display={'flex'}
                            justifyContent="end">
                            <NewVehicleTag />
                          </Box>
                        )}
                        {isLoweredInPrice() && (
                          <Box
                            marginBottom={1}
                            display={'flex'}
                            justifyContent="end">
                            <LoweredPriceTag />
                          </Box>
                        )}
                        {hasGuarantee() && (
                          <Box
                            marginBottom={1}
                            display={'flex'}
                            justifyContent="end">
                            <HasGuaranteeTag />
                          </Box>
                        )}
                        {isEngros() && (
                          <Box display={'flex'} justifyContent="end">
                            <EngrosTag />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box marginTop={1}>
              {dataList.map((text, textIndex) => {
                return (
                  <Box
                    display={'inline-block'}
                    marginRight="6px"
                    marginBottom="6px"
                    key={textIndex}>
                    <DefaultTag text={text} />
                  </Box>
                );
              })}
            </Box>

            <Price marginTop={4}>{props.item?.priceFormatted}</Price>
            {isLoweredInPrice() ? (
              <Typography color="#1066B6" fontSize={15}>
                {`Nedsat ${thousandNumberSeperator(
                  props.item.changeInMaxPrice * -1,
                )} kr (før ${thousandNumberSeperator(
                  props.item.previousMaxPrice,
                )} kr)`}
              </Typography>
            ) : (
              <PriceLabel>{props.item?.priceLabel}</PriceLabel>
            )}

            {!props['exclude-owner'] && (
              <>
                <Box marginTop={2}>
                  <Divider />
                </Box>

                <Box marginTop={1.5}>
                  <Grid
                    container
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}>
                    <Grid item>
                      <FooterTypography>
                        {props.item?.owner.profileType ===
                          UserProfileType.Company && (
                          <>{props.item?.owner.name}</>
                        )}
                        {props.item?.owner.profileType ===
                          UserProfileType.Private && (
                          <>
                            {t('Labels.PrivateSeller')},{' '}
                            {props.item?.owner.city}
                          </>
                        )}
                      </FooterTypography>
                    </Grid>
                    <Grid item>
                      <FooterTypography>
                        {FooterLocationDisplay(props.item, userCoordinates)}
                      </FooterTypography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </VipLink>
    </Grid>
  );
};
